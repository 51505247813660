import { ReactOnRails } from 'react-on-rails/node_package/lib/types';

declare global {
    interface Window { 
        ReactOnRails: ReactOnRails
    }
}

window.ReactOnRails = window.ReactOnRails || require('react-on-rails').default;

export default window.ReactOnRails