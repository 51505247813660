import React from "react";
import TranslationsHandler from "./TranslationsHandler";

export default function Translations ({
    namespace,
    translations
}: {
    namespace: string
    translations: {key: any}
}) {
    TranslationsHandler.add(namespace, translations)
    return (<div></div>)
}