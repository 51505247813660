import React from "react";
import styled from "styled-components"
import { EColor, EIconGlyph, ESize } from "../../styleConstants";
import { breakPoint, rem } from "../styleUtils";

type IconContainerProps = {
    size: ESize
    color: EColor
}

const IconContainer = styled.span<IconContainerProps>`
    font-family: "readly-glyphs";
    color: ${props => props.color ? props.color : EColor.FloralWhite};

    display: flex;
    user-select: none;

    width:  ${props => rem(props.size)};
    height: ${props => rem(props.size)};

    font-size:   ${props => rem(props.size)};
    line-height: ${props => rem(props.size)};

`

export default function Icon ({
    icon,
    size,
    color
}: {
    icon: EIconGlyph
    size: ESize
    color?: EColor
}) {
    return (
        <IconContainer size={size} className="notranslate" color={color}>
            {icon}
        </IconContainer>
    )
}