export enum EColor {
    None            = "none",
    FloralWhite     = "#FFFAE9",
    Russian         = "#1E1E28",
    ColombianBlue   = "rgba(175, 200, 255, 0.1)",
    MarlinGray      = "rgba(58, 58, 70, 1)",
    SunglowYellow   = "rgba(255, 187, 34, 1)",
    TapestryRed     = "#B77173",
    MountainGray    = "#888888",
    RussianGradient = "linear-gradient(180deg, #1E1E28 0%, #000000 100%)",
    FruitSaladGreen = "#51A951",
    Black           = "#000000",
    White           = "White",

    HeroGradientRussianOrange = "linear-gradient(313.77deg,rgba(255,187,34,0.38) 0%,#1E1E28 69.84%)"
} 


export interface IFontSize {
    size: number,
    lineHeight: number,
    h: number
}

export type FontSize = 1|2|3|4|5|6|7|8|9|10|11

export const MFontSizeProperties = new Map<number, IFontSize>([
    [1,  {size: 14, lineHeight: 16, h: 5}],
    [2,  {size: 16, lineHeight: 20, h: 4}],
    [3,  {size: 18, lineHeight: 24, h: 3}],
    [4,  {size: 20, lineHeight: 24, h: 3}],
    [5,  {size: 22, lineHeight: 24, h: 2}],
    [6,  {size: 25, lineHeight: 32, h: 2}],
    [7,  {size: 28, lineHeight: 32, h: 2}],
    [8,  {size: 32, lineHeight: 40, h: 1}],
    [9,  {size: 36, lineHeight: 40, h: 1}],
    [9,  {size: 38, lineHeight: 48, h: 1}],
    [10, {size: 40, lineHeight: 48, h: 1}],
    [11, {size: 48, lineHeight: 56, h: 1}],
])

export enum ESize {
    none        = 0,
    tiny        = 4,
    extra_small = 8,
    small       = 16,
    default     = 24,
    medium      = 32,
    large       = 48,
    extra_large = 64,
}

export enum EIconGlyph {
    ring          = "A",
    user_add      = "B",
    user          = "C",
    trash         = "D",
    star          = "E",
    star_filled   = "u",
    bubble        = "F",
    sort          = "G",
    settings      = "H",
    search        = "I",
    pin           = "J",
    newspaper     = "K",
    more          = "L",
    message       = "M",
    menu          = "N",
    magazine      = "O",
    logout        = "P",
    login         = "Q",
    locked        = "R",
    info          = "S",
    home          = "T",
    flag          = "U",
    filter_2      = "V",
    filter        = "W",
    faq           = "X",
    facebook      = "Y",
    error         = "a",
    edit          = "b",
    download      = "c",
    close         = "d",
    check         = "e",
    bookmark      = "f",
    bell_on       = "g",
    bell_off      = "h",
    arrow_up      = "i",
    arrow_right   = "j",
    arrow_left    = "k",
    arrow_down    = "l",
    chevron_up    = "m",
    chevron_right = "n",
    chevron_left  = "o",
    chevron_down  = "p",
    eye_on        = "q",
    eye_off       = "r",
    share_ios     = "s",
    share_android = "t",
    refresh       = "v",
    screen_off    = "w",
    screen_on     = "x",
}