import React from "react";
import styled from "styled-components"
import { EColor, ESize, FontSize, IFontSize, MFontSizeProperties } from "../../styleConstants";
import { breakPoint, rem, styleReset } from "../styleUtils";

export const P = styled.p``

export default function Paragraph ({
    children,
    size,
    weight,
    margin,
    color,
    align
}: {
    children: string | JSX.Element | JSX.Element[] | (string | JSX.Element)[] 
    size: FontSize
    weight?: number
    margin?: ESize
    color?: EColor
    align?: "left"|"right"|"center"
}) {

    const fontSize = MFontSizeProperties.get(size)
    const PElement = styled(P)`
        ${styleReset}
        text-align: ${align};
        font-size: ${rem(fontSize.size)};
        line-height: ${rem(fontSize.lineHeight)};
        font-weight: ${weight ? weight : 400};
        margin-bottom: ${margin ? rem(margin) : 0};
        color: ${color || EColor.FloralWhite};

        & a {
            ${styleReset}
            color: ${EColor.SunglowYellow};
            text-decoration: underline;
            cursor: pointer;

            a:hover {
                color: ${EColor.SunglowYellow};
                text-decoration: underline;
                cursor: pointer;
            }
        }
    `;

    return (
        <PElement>
            {children}
        </PElement>
    )
}