interface ITranslationKey {
    [key: string]: ITranslationKey | string
}

declare global {
    interface Window { 
        translations: ITranslationKey | string,
    }
}

export default class TranslationsHandler {

    static resolve (namespace: string) : [boolean, string] {
        try {
            let namespaceLevels = namespace.split(".")
    
            let current = window.translations;
    
            for (let namespaceLevel of namespaceLevels) {
                current = current[namespaceLevel]
            }

            if (typeof current !== "string") {
                throw "Missing translation"
            }
    
            return [true, current as string]
        } catch (e) {
            return [false, `Missing Translation [${namespace}]`]
        }
    }

    static get (namespace: string) : string {
        const [, string] = TranslationsHandler.resolve(namespace)
        return string
    }

    static exists (namespace: string) : boolean {
        const [exists, string] = TranslationsHandler.resolve(namespace)
        return string === "null" ? false : exists
    }

    static add (namespace: string, data: string|object) {
        if (!window.translations) window.translations = {}

        let namespaceLevels = namespace.split(".")

        try {
            let translations = data

            if (typeof translations == "string") {
                translations = JSON.parse(translations) as ITranslationKey
            }

            let current = window.translations

            //Populate Namespace
            for (let namespaceLevel of namespaceLevels) {
                window.translations[namespaceLevel]
                if (!current[namespaceLevel]) {
                    current[namespaceLevel] = {} as ITranslationKey
                }

                current = current[namespaceLevel]
            }

            //Add translations to namespace
            Object.assign(current, translations)
        } catch (e) {
            console.error("Cant add translations for namespace", namespace)
            console.error(e)
            return
        }
    }
}