import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {createBreakpoint, createMap} from 'styled-components-breakpoint';

const windowDimensionCache = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener("resize", () => {
    windowDimensionCache.height = window.innerHeight;
    windowDimensionCache.width = window.innerWidth;
});


export const baseRootFontSize = 12

//Cache currentRootFontSize when resizing window
export function getCurrentRootFontSize () : number {
    try {
        const root = document.querySelector(':root')
        return parseFloat(getComputedStyle(root).fontSize.replace("px", ""))
    } catch (e) {
        return baseRootFontSize
    }
}

var currentRootFontSize = getCurrentRootFontSize()

window.addEventListener("resize", () => {
    currentRootFontSize = getCurrentRootFontSize()
})
window.addEventListener("DOMContentLoaded", () => {
    currentRootFontSize = getCurrentRootFontSize()
})


export function rem (px, raw=false) {
    return raw ? `${px / baseRootFontSize}` : `${px / baseRootFontSize}rem`
}

export function rootFontSize () : number {
    return currentRootFontSize
}

export function remSaleFactor () : number {
    return rootFontSize() / baseRootFontSize
}

export function windowWidthIsMobile () : boolean {
    return windowDimensionCache.width < 769
}

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(windowWidthIsMobile())

    useEffect(() => {
        window.addEventListener("resize", () => {
            setIsMobile(windowWidthIsMobile())
        })
    }, [])

    return isMobile
}

export function getBrowserScrollBarWidth() {
    return windowDimensionCache.width - document.documentElement.clientWidth
}


export function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: windowDimensionCache.width,
        height: windowDimensionCache.height,
    })

    useEffect(() => {
        function handleResize() {
          setWindowSize({
            width: windowDimensionCache.width,
            height: windowDimensionCache.height,
          })
        }

        window.addEventListener("resize", handleResize);

        handleResize()

        return () => window.removeEventListener("resize", handleResize);
    }, [])
    
    return windowSize
}

export function useMouseDragScroll (direction: "horizontal"|"vertical"|"both", enabled=true) {
    
    const xEnabled = (direction === "horizontal" || direction === "both")
    const yEnabled = (direction === "vertical"   || direction === "both")

    const scrollRef = useRef<HTMLDivElement>(null)
    const contentRef = useRef<HTMLDivElement>(null)
    const easingEnabled = useRef<boolean>(false)

    useEffect(() => {
        if (!enabled) 
            return
            
        const scrollArea = scrollRef.current

        function easing(positionX: number, positionY: number, velocityX: number, velocityY: number) {
            const newVelocityX = velocityX * 0.92
            const newVelocityY = velocityY * 0.92

            const newPositionX = positionX - velocityX
            const newPositionY = positionY - velocityY

            if (xEnabled) scrollRef.current.scrollLeft = newPositionX
            if (yEnabled) scrollRef.current.scrollTop = newPositionY

            if (easingEnabled.current && (Math.abs(velocityX) > 0.1 || Math.abs(velocityY) > 0.1))
                window.requestAnimationFrame(() => easing(newPositionX, newPositionY, newVelocityX, newVelocityY))
        }


        function pointerDownHandler (event: PointerEvent) {
            
            const scrollArea = scrollRef.current
            
            const capturedScrollPositionX = scrollArea.scrollLeft
            const capturedPointerPositionX = event.pageX / remSaleFactor()

            const capturedScrollPositionY = scrollArea.scrollTop
            const capturedPointerPositionY = event.pageY / remSaleFactor()

            var lastPositonX = 0
            var velocityX = 0

            var lastPositonY = 0
            var velocityY = 0
            
            var lastSample = undefined
            
            easingEnabled.current = false
            
            function pointerUpHandler (event: PointerEvent) {
                console.log("Up")

                window.removeEventListener("pointermove", pointerMoveHandler)
                window.removeEventListener("pointerup", pointerUpHandler)
                easingEnabled.current = true
                contentRef.current.style.pointerEvents = "all";

                easing(scrollRef.current.scrollLeft, scrollRef.current.scrollLeft, velocityX, velocityY)
            }

            function pointerMoveHandler (event: PointerEvent) {
                console.log("Move")
                //if (easingEnabled.current === false)
                    //return
                    
                const x = event.pageX / remSaleFactor()
                const y = event.pageY / remSaleFactor()

                const deltaX = capturedPointerPositionX - x
                const deltaY = capturedPointerPositionY - y

                if (Math.abs(deltaX) > 3)
                    contentRef.current.style.pointerEvents = "none";
                
                if (lastSample !== undefined) {
                    const timeDelta = performance.now() - lastSample
                    velocityX = ((x - lastPositonX) * 16) / timeDelta
                    velocityY = ((y - lastPositonY) * 16) / timeDelta
                }

                lastSample = performance.now()

                lastPositonX = x
                lastPositonY = y

                if (xEnabled) scrollRef.current.scrollLeft = capturedScrollPositionX + deltaX
                if (yEnabled) scrollRef.current.scrollTop = capturedScrollPositionY + deltaY
            }

            window.addEventListener("pointerup", pointerUpHandler)
            window.addEventListener("pointermove", pointerMoveHandler)
        }
        
        scrollArea.addEventListener("pointerdown", pointerDownHandler)

        return () => scrollArea.removeEventListener("pointerdown", pointerDownHandler)

    }, [scrollRef, contentRef])

    return {
        scrollRef: scrollRef,
        contentRef: contentRef
    }
}

const breakpoints = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
}

export const breakPoint = createBreakpoint(breakpoints)
export const breakMap = createMap(breakpoints);


const breakpointsV2 = {
    phone: 0,
    tablet: 744,
    desktop: 1280,
}

export const breakPointV2 = createBreakpoint(breakpointsV2)
export const breakMapV2 = createMap(breakpointsV2);

export const styleReset = `
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-weight: inherit;
    font-style: inherit;
    font-size: inherit;
    font-family: inherit;
    vertical-align: baseline;
    letter-spacing: inherit;
    color: inherit;
`