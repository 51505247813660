import React from "react";
import styled from "styled-components"
import { EColor, ESize } from "../../styleConstants";
import { breakPoint, rem } from "../styleUtils";

type SpacerElementProps = {
    width: ESize
    height: ESize
}

const SpacerElement = styled.div<SpacerElementProps>`
    width: ${props => rem(props.width)};
    height: ${props => rem(props.height)};
`

export default function Spacer ({
    width = ESize.tiny,
    height = ESize.tiny
}: {
    width?: ESize
    height?: ESize
}) {
    return (
        <SpacerElement width={width} height={height} />
    )
}