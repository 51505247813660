import React from "react";
import styled from "styled-components"
import { EColor, ESize, FontSize, IFontSize, MFontSizeProperties } from "../../styleConstants";
import { breakPoint, rem, styleReset } from "../styleUtils";

export const H1 = styled.h1``
export const H2 = styled.h1``
export const H3 = styled.h1``
export const H4 = styled.h1``
export const H5 = styled.h1``
export const H6 = styled.h1``

function getHElement (h: number) {
    switch (h) {
        case 1:
            return H1
        case 2:
            return H2
        case 3:
            return H3
        case 4:
            return H4
        case 5:
            return H5
        case 6:
            return H6
        default: {
            return H6
        }
    }
}

export default function Title ({
    children,
    size,
    weight,
    margin,
    color,
    align="left"
}: {
    children: string | JSX.Element | JSX.Element[] | (string | JSX.Element)[]
    size: FontSize
    weight?: number
    margin?: ESize
    color?: EColor
    align?: "left"|"right"|"center"
}) {
    const fontSize = MFontSizeProperties.get(size)
    const HElement = styled(getHElement(fontSize.h))`
        ${styleReset};
        text-align: ${align};
        font-size: ${rem(fontSize.size)};
        line-height: ${rem(fontSize.lineHeight)};
        font-weight: ${weight ? weight : 700};
        margin-bottom: ${rem(margin ? margin : 0)};
        color: ${color || EColor.FloralWhite};
    `;

    return (
        <HElement>
            {children}
        </HElement>
    )
}